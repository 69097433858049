<template>
<div class="animated fadeIn">
    <credits-situation-worker v-if="showCreditsSituationWorker" :baseUrl="baseUrl"></credits-situation-worker>
    <credits-situation-customer v-if="showCreditsSituationCustomer" :baseUrl="baseUrl"></credits-situation-customer>
</div>
</template>

<script>
import _ from 'lodash'
import CreditsSituationWorker from './CreditsSituationWorker'
import CreditsSituationCustomer from './CreditsSituationCustomer';
export default {
    name: 'CreditsSituation',
    components: {
        'credits-situation-worker': CreditsSituationWorker,
        'credits-situation-customer': CreditsSituationCustomer
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            enableCard: false,
        }
    },
    computed: {
        showCreditsSituationWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showCreditsSituationCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
