<template>
<div class="animated fadeIn">
    <b-card class="m-0 mb-3 p-0" no-body>
        <div slot="header">
            <q-item class="p-0 d-flex justify-items-center">
                <q-item-section side class="p-0 text-dark">
                    <h3 class="m-0">{{ translate('credits_situation') }}</h3>
                </q-item-section>
            </q-item>
        </div>
    </b-card>
    <template v-if="credits_situations && credits_situations.length !== 0">
        <div class="row offers-cards">
            <div class="pl-2 pr-2 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-5ths text-center mb-3" square v-for="(situation,i) in credits_situations" :key="'credits_situation-' + i + '-' + situation.service_id">
                <q-card class="text-center" square>
                    <template v-if="situation.service_image_link">
                        <q-carousel height="200px" class="service-image" v-model="slide">
                            <q-carousel-slide :name="1" class="p-0">
                                <img :src="situation.service_image_link" class="slide-main-image" />
                            </q-carousel-slide>
                        </q-carousel>
                    </template>
                    <template v-else>
                        <q-carousel height="200px" class="service-image no-image" v-model="slide">
                            <q-carousel-slide :name="1">
                                <div class="absolute-center custom-caption text-center">
                                    <q-icon name="image" />
                                </div>
                            </q-carousel-slide>
                        </q-carousel>
                    </template>
                    <q-card-section class="p-0" v-for="(credits_situation,i) in situation.credits" :key="'credits_situation-' + i + '-' + credits_situation.expiration_date">
                        <div class="bg-light text-dark p-3">
                            <i class="fas fa-swimmer text-blue-6 mr-1"></i>{{ situation.service_name }}
                            <div class="text-h6 text-primary">{{ translate('credits_situations_total_credits') }} {{ credits_situation.total_credits }}</div>
                            <div class="text-h6"><i class="fa fa-calendar text-dark mr-1"></i>{{ translate('credits_situations_expire_date') }}: <span>{{ credits_situation.expiration_date === ('never') ? translate('expires_never') : prettyDate(credits_situation.expiration_date) }}</span></div>
                        </div>
                        <div class="bg-green text-white p-3">
                            <div class="text-h5 font-weight-bold">{{ translate('credits_situations_available_credits') }} {{ credits_situation.available_credits }}</div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md bg-dark p-3 text-white badge no-border-radius">
                                <div>{{ translate('credits_situations_consumed_credits') }}&nbsp;<span>{{ credits_situation.consumed_credits }}</span></div>
                            </div>
                            <div class="col-md bg-blue p-3 text-white badge no-border-radius">
                                <div>{{ translate('credits_situations_reserved_credits') }}&nbsp;<span>{{ credits_situation.reserved_credits }}</span></div>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </div>
        </div>
    </template>
    <template v-else>
        <q-card class="p-0 mb-3" square>
            <q-card-section>{{ translate('credits_situation_no_credits') }}</q-card-section>
        </q-card>
    </template>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'CreditsSituationCustomer',
    data: function () {
        return {
            credits_situations: [],
            page: 1,
            customer_id: 0,
            slide: 1
        }
    },
    created: function () {
        this.getCreditsSituations()
    },
    methods: {
        getCreditsSituations: function () {
            var url = baseUrl + 'credits/available'
            
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.status && response.data.items && response.data.items.length) {
                        this.credits_situations.push(...response.data.items.map(entity => {
                            let array = entity
                            array['service_image_link'] = entity.service_image_link ? baseUrl + entity.service_image_link : null
                            return array
                        }))
                    } else {
                        this.credits_situations = []
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.service-image {
    width: 100%;
}

.no-image {
    background: rgba(0, 0, 0, .1);

    .q-icon {
        color: rgba(0, 0, 0, .15);
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
}

.offers-cards {
    max-width: calc(100% + 16px)!important;
    width: calc(100% + 16px)!important;
    margin-left: -8px!important;
    margin-right: -8px!important;

    .card {
        overflow: hidden !important;
    }
    .card-body {
        justify-content: stretch;
        display: flex;
        flex-direction: column;

        .align-buttons-bottom {
            flex-grow: 1;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;

            > div {
                height: auto !important;
            }
        }
    }
    .excerpt {
        font-size: .85rem;
    }
}

@media (max-width: 767px) {
    .q-carousel.service-image {
        height: 90vw !important;
    }
}
</style>
